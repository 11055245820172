import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Avslutte abonnement" />
    <div className="longtext">
      <h2>Avslutte abonnement</h2>
      <p>
        Abonnementet håndteres av Apple og må derfor avsluttes via App Store.
        Dette er en rask og enkel prosess:{" "}
      </p>
      <p>
        <a href="https://support.apple.com/en-us/HT202039">
          Avslutt abonnement i App Store
        </a>
      </p>

      <h3>Greit å vite før du avslutter:</h3>
      <ul>
        <li>
          Vi skal ikke overtale deg til å fortsette om Mente ikke fungerer for
          deg, men vi blir veldig glad for en tilbakemelding på hvorfor du
          avslutter. Som en liten startup er alle tilbakemeldinger nyttige for
          videre utvikling, så gi oss gjerne beskjed ved å trykke på
          snakkeboblen nederst
        </li>
        <li>
          Du kan fortsette å bruke abonnementet ut perioden du har betalt for
        </li>
      </ul>
    </div>
  </Layout>
)

export default Page
